import React from 'react';
import CV from '../../assets/OngBoonLoong-Java_Developer.pdf';

const CTA = () => {
  return (
    <div className="cta">
      
    </div>
  );
};

export default CTA;
