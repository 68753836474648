import React from 'react';
import { FaAward } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import ME from '../../assets/me.jpg';
import './intro.css';

const Intro = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>5 years +</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Financial Industry</h5>
              <small>(Malaysia and International)</small>
            </article>
          </div>
          <p>Enthusiastic and well-organized Java Developer with over 5 years of experience in Financial Industry (Malaysia and International). Proven ability to work independently or as a part of team and always focused on goals and delivering success to rapidly evolving and dynamic environments.</p>
        </div>
      </div>
    </section>
  )
}

export default Intro