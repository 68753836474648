import React from 'react';
import './portfolio.css';

const Portfolio = () => {
  const soloProjects = [
    {
      id: 1,
      title: 'library-backend-springboot',
      description:
        'Back-end Application for Bookstore App',
      technologies: 'Java | Spring Boot | Spring Security | Hibernate | MySQL',
      github: 'https://github.com/obl0702/library-backend-springboot',
    },
    {
      id: 2,
      title: 'library-frontend-reactjs',
      description:
        'Front-end Application for Bookstore App (Fully responsive)',
      technologies: 'React | Typescript | OktaAuth',
      github: 'https://github.com/obl0702/library-frontend-reactjs',
    },
    {
      id: 3,
      title: 'sfg-pet-clinic',
      description: 'This project was built step-by-step from original Spring Pet Clinic following the online course and example in springframeworkguru/ sfg-pet-clinic. It has two modules which are pet-clinic-data and pet-clinic-web.',
      technologies: 'Java | Spring Boot | Hibernate | H2 Database | Thymeleaf | Bootstrap | JQuery | JUnit & Mockito',
      github: 'https://github.com/obl0702/sfg-pet-clinic',
    },
    {
      id: 4,
      title: 'spring5-reactive-mongo-recipe-app',
      description:
        'This project represents CRUD operations with Recipe object. In this project i have implemented Reactive Types(Mono, Flux) with WebFlux and database with reactive driver MongoDB.',
      technologies: 'Java | Spring Boot | WebFlux | Hibernate | MongoDB | Thymeleaf | Bootstrap | JQuery | JUnit & Mockito',
      github: 'https://github.com/obl0702/spring5-reactive-mongo-recipe-app',
    },
    {
      id: 5,
      title: 'spring5-mvc-rest',
      description:
        'This project represents API with GET, POST, PUT, PATCH, DELETE operations to Category, Customer, Vendor. The project using JAXB to generate Classes from XML Schema. Documentation was generated via Swagger.',
      technologies: 'Java | Spring Boot | Hibernate | H2 Database | MapStruct | Swagger | JUnit & Mockito',
      github: 'https://github.com/obl0702/spring5-mvc-rest',
    },
    {
      id: 6,
      title: 'spring5-webflux-rest',
      description:
        'In this project, I have implemented Reactive Types(Mono, Flux) with WebFlux and database with reactive driver MongoDB.',
      technologies: 'Java | Spring Boot | WebFlux | Hibernate | H2 Database | MapStruct | Swagger | JUnit & Mockito',
      github: 'https://github.com/obl0702/spring5-webflux-rest',
    },
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>GitHub's Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-content">
              <h3>{pro.title}</h3>
              <p>{pro.description}</p>
              <p className="portfolio__item-technologies">{pro.technologies}</p>
            </div>
            <div style={{ height: '50px'}}></div>
            <div className="portfolio__item-cta">
              <a
                href={pro.github}
                target="_blank"
                className="btn"
                rel="noreferrer"
              >
                GitHub
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
